<template>
  <div class="box">
    <img
      src="https://ytk-front.oss-cn-beijing.aliyuncs.com/hybrid/all/sharePage/sharePage.jpeg"
      alt=""
    />
    <a
      class="defCli"
      href="https://a.app.qq.com/o/simple.jsp?pkgname=com.yanda.ydapp"
      target="_blank"
    >
      <button class="postBut">
        <span class="spanTit"> 下载研大医题库，开始估分 </span>
        <span> (若点击后无法下载，请"在浏览器中打开"此页面) </span>
      </button>
    </a>
  </div>
</template>

<script>
import constant from "@/common/constant";
export default {
  name: "",
  data() {
    return {};
  },
  created() {
    this.arrList();
  },
  mounted() {},
  methods: {
    arrList() {
      this.axios
        .get(constant.getUserStudyPlanBaseInfoByUserId, {
          params: {
            userId: "124522",
          },
        })
        .then((res) => {
          console.log(res);
        });
    },
  },
};
</script>

<style scoped lang="less">
.box {
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    margin-bottom: 200px;
  }
  .defCli {
    outline: none;
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    .postBut {
      position: fixed;
      bottom: 100px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 660px;
      height: 100px;
      background: #3399ff;
      border: 0;
      display: flex;
      border-radius: 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .spanTit {
        color: white;
        font-size: 38px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      span {
        color: white;
      }
    }
  }
}
</style>
