<template>
   <div class="box">
   看看看！！！
   </div>
</template>

<script>
export default {
  name:'',
  data(){
   return {
 
   }
  },
  created(){
 
  },
  mounted(){
 
  },
  methods:{
 
  },
}
</script>

<style scoped lang='less'>
  .box{
    width: 100%;
    text-align: center;
  }

</style>
