"use strict";

import qs from "qs";
import Vue from "vue";
import axios from "axios";
// import store from '../store/index'
// import router from "../router";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
    // baseURL: process.env.baseURL || process.env.apiUrl || ""
    // timeout: 60 * 1000, // Timeout
    withCredentials: true // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        config.headers.platform = 'kaoyan_h5'
        // config.headers.userId = store.state.userId || localStorage.getItem('userId') || ''
        // 将请求转为form 表单形式提交,修改data格式，将自动变更Content-Type为=》  Content-Type: application/x-www-form-urlencoded
        // config.data = qs.stringify(config.data);
        // if (config.headers.type === 22) {
        //     config.data = qs.stringify(config.data);
        // }
            config.data = qs.stringify(config.data);
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
        if (response.data.success) {
            return response.data.entity;
        } else if (response.data.rlt) {
            return response.data.data;
        } else {
            return Promise.reject(response.data);
        }
    },
    function (error) {
        // Do something with response error
        return Promise.reject(error);
    }
);

Plugin.install = function (Vue) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            }
        },
        $axios: {
            get() {
                return _axios;
            }
        }
    });
};

Vue.use(Plugin);

export default Plugin;
