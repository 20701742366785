<template>
   <div class="box">
       <div class="headBack">
           <span class="headBackTit">下载研大医题库App</span>
           <span>免费刷海量真题</span>
       </div>
       <img :src="webUrl" alt="">
       <span class="botFont">下载方法：长按二维码保存-打开微信扫一扫-从相册中选择该码</span>
   </div>
</template>

<script>
export default {
  name:'',
  data(){
   return {
       webUrl : 'https://ytk-front.oss-cn-beijing.aliyuncs.com/wx/adjust/newVersion/home/webUrlImg.png'
   }
  },
  created(){
 
  },
  mounted(){
 
  },
  methods:{
 
  },
}
</script>

<style scoped lang='less'>
.box{
    width: 100%;
    display: flex;
    flex-direction: column;
    .headBack{
        width: 100%;
        height: 600px;
        background: #3399ff;
        border-bottom-left-radius: 20%;
        border-bottom-right-radius: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .headBackTit{
            margin:80px 0 20px;
            font-size: 50px;
            font-weight: bold;
        }
        span{
            color: white;
        }
    }
    img{
        width: 500px;
        height: 500px;
        margin: -300px auto 0;
    }
    .botFont{
        // color: chartreuse;
        margin: 60px auto 100px;
        width: 600px;
        display: block;
        text-align: center;
    }
}

</style>
