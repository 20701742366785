import Vue from 'vue'
import VueRouter from 'vue-router'
//主页
import home from '@/page/home/home'
//分享h5链接 app和小程序
import hybridSharePage from '@/page/hybridSharePage/hybridSharePage'
//分享二维码
import qrShareImg from '@/page/qrShareImg/qrShareImg'
//分享页 table点击
import tabPage from '@/page/tabPage/tabPage'
import tabPageTwo from '@/page/tabPageTwo/tabPageTwo'
//分享页 中医分享
import TCMPage from '@/page/TCMPage/TCMPage'
import TCMPageTwo from '@/page/TCMPageTwo/TCMPageTwo'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'home',
        component: home
    },
    {
        path: '/hybridSharePage',
        name: 'hybridSharePage',
        component: hybridSharePage
    },
    {
        path: '/qrShareImg',
        name: 'qrShareImg',
        component: qrShareImg
    },
    {
        path: '/tabPage',
        name: 'tabPage',
        component: tabPage
    },
    {
        path: '/tabPageTwo',
        name: 'tabPageTwo',
        component: tabPageTwo
    },
    {
        path: '/TCMPage',
        name: 'TCMPage',
        component: TCMPage
    },
    {
        path: '/TCMPageTwo',
        name: 'TCMPageTwo',
        component: TCMPageTwo
    },

]

const router = new VueRouter({
    routes,
    mode: 'history'
})

export default router