<template>
  <div class="box">
    <img
      src="https://ytk-front.oss-cn-beijing.aliyuncs.com/hybrid/all/tabPage/tabPageTwo.png"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.box {
  width: 100%;
  img {
    width: 100%;
    display: block;
  }
}
</style>
